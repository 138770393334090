@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
* {
  margin: 0;
  padding: 0;
}

body, html {
  background-color: white;
}

h1, h2, h3, h4, h5, h6, p, a {
  font-family: "Open Sans", sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600 !important;
}

p {
  font-size: 18px;
  line-height: 24px;
}
p strong {
  font-weight: bold;
}

a.btn-contato {
  padding: 8px 24px;
  background-color: #51A8B1;
  text-decoration: none;
  color: #ffffff;
  display: inline-block;
  border-radius: 50px;
}
a.btn-contato:hover {
  opacity: 0.8;
  transition: linear 0.1s;
}

figure.box-depoimento {
  padding: 20px;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.2);
  margin: 16px;
  border-radius: 4px;
}
figure.box-depoimento img {
  filter: grayscale(1);
}
figure.box-depoimento figcaption p {
  font-size: 16px;
}
figure.box-depoimento figcaption small {
  line-height: 14px;
  font-size: 14px;
  display: block;
  margin-bottom: 4px;
}
figure.box-depoimento figcaption small strong {
  font-size: 16px;
}
figure.box-depoimento figcaption a {
  color: #51A8B1;
  font-weight: bold;
  text-decoration: underline;
}
figure.box-depoimento:hover img {
  filter: grayscale(0);
  transition: linear 0.1s;
}

header {
  background-color: #D61C38;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 2;
}
header nav a.navbar-brand img {
  width: 75%;
}
header ul.navbar-nav li a {
  color: #ffffff;
  font-weight: bold;
}
header ul.navbar-nav li a:hover {
  color: aquamarine;
}

section.padrao {
  padding-top: 80px;
  padding-bottom: 80px;
}
section.padrao hgroup {
  text-align: center;
  margin-bottom: 24px;
}
section.banner {
  background-color: #B22C3A;
}
section.banner .carousel-indicators {
  position: inherit !important;
  justify-content: flex-end;
}
section.incentive h1 {
  font-size: 26px;
  color: #D61C38;
  font-weight: 800 !important;
  text-transform: uppercase;
}
section.box-de-acao {
  background-color: #B22C3A;
  color: #ffffff;
  text-align: center;
}
section.premiacao {
  background: linear-gradient(125deg, rgb(235, 235, 235) 0%, rgb(255, 255, 255) 100%);
}
section.premiacao hgroup h2 {
  font-size: 32px;
  color: #51A8B1;
  margin-bottom: 8px;
  font-weight: bold !important;
}
section.premiacao hgroup h3 {
  font-size: 24px;
  color: #6B6B6B;
}
section.premiacao h4 {
  color: #B22C3A;
  font-weight: bold !important;
  font-size: 20px;
  margin-bottom: 24px;
}
section.premiacao ul {
  padding: 0;
}
section.premiacao ul li {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 22px;
  list-style-position: inside;
  list-style-image: url(../images/setas-lista.svg);
  padding: 4px 0;
  margin-bottom: 4px;
}
section.depoimentos hgroup h5 {
  color: #51A8B1;
  font-size: 36px;
  font-weight: bold !important;
  margin-bottom: 4px;
}
section.depoimentos hgroup h6 {
  color: #6B6B6B;
  font-size: 20px;
}

/*Final das configurações das seções*/
.depoimentos-slider {
  width: 100%;
}

.slick-prev:before, .slick-next:before {
  color: #B22C3A !important;
}

.carousel {
  background-color: #B22C3A;
}

.carousel .container {
  position: relative;
}

.formulario {
  width: 100%;
  height: auto;
  padding: 24px;
}
.formulario legend, .formulario p {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}
.formulario label {
  display: block;
  display: none;
}
.formulario small {
  font-size: 12px;
  color: #ffffff;
}
.formulario input[type=text], .formulario input[type=number], .formulario input[type=phone], .formulario input[type=email], .formulario textarea, .formulario select {
  background-color: #B22C3A;
  border: 2px solid #ffffff;
  color: #ffffff;
  display: block;
  padding: 4px;
  outline: 0;
  width: 100%;
  font-size: 14px;
}
.formulario input[type=text]::placeholder, .formulario input[type=number]::placeholder, .formulario input[type=phone]::placeholder, .formulario input[type=email]::placeholder, .formulario textarea::placeholder, .formulario select::placeholder {
  color: #ffffff;
}
.formulario input[type=text]:focus, .formulario input[type=number]:focus, .formulario input[type=phone]:focus, .formulario input[type=email]:focus, .formulario textarea:focus, .formulario select:focus {
  background-color: #ffffff;
  color: #B22C3A;
  transition: linear 0.1s;
}
.formulario input[type=text]:focus::placeholder, .formulario input[type=number]:focus::placeholder, .formulario input[type=phone]:focus::placeholder, .formulario input[type=email]:focus::placeholder, .formulario textarea:focus::placeholder, .formulario select:focus::placeholder {
  color: #B22C3A;
}
.formulario input[type=submit] {
  border: none;
  background-color: #51A8B1;
  color: #ffffff;
  padding: 8px 16px;
}
.formulario input[type=submit]:hover {
  opacity: 0.8;
  transition: linear 0.1s;
}

footer {
  padding-top: 28px;
  padding-bottom: 28px;
  background: #F8F8F8;
}
footer hr.divisor {
  border: 0;
  height: 100px;
  width: 4px;
  border-radius: 50px;
  background: rgba(0, 0, 0, 0.2);
  margin: 0 auto;
}
footer ul.icones-social-media {
  padding: 0;
}
footer ul.icones-social-media li {
  display: inline;
}

.base {
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 575.99px) and (max-width: 1199.98px) {
  img.img-divisor-incentivo {
    transform: rotate(90deg);
  }
  section {
    padding-bottom: 40px;
  }
  section.padrao {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  footer {
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
  }
  footer img.logotipo-rodape {
    width: 50% !important;
    margin: 0 auto;
  }
  footer hr.divisor, footer hr {
    border: 0;
    height: 2px;
    width: 75%;
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.2);
    margin: 16px auto;
  }
}
@media (max-width: 575.98px) {
  section.padrao {
    padding-top: 60px;
    padding-bottom: 60px;
    text-align: center;
  }
  section.incentive h1 {
    font-size: 20px;
    color: #D61C38;
    font-weight: 800 !important;
    text-transform: uppercase;
    margin-bottom: 18px;
  }
  section.premiacao hgroup h2 {
    font-size: 24px;
  }
  section.premiacao hgroup h3 {
    font-size: 18px;
  }
  section.premiacao h4 {
    font-size: 16px;
  }
  section.premiacao ul {
    padding: 0;
  }
  section.premiacao ul li {
    font-size: "Open Sans", sans-serif;
    display: block;
    background-color: #B22C3A;
    color: #ffffff;
    margin-bottom: 4px;
    font-size: 18px;
    line-height: 22px;
    list-style: none;
    padding: 8px;
  }
  footer {
    text-align: center;
  }
  footer img.logotipo-rodape {
    width: 50% !important;
    margin: 0 auto;
  }
  footer a {
    color: #6B6B6B;
  }
  footer hr.divisor, footer hr {
    border: 0;
    height: 2px;
    width: 75%;
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.2);
    margin: 16px auto;
  }
}