//Cores
$cor-vermelho:#D61C38;
$cor-vermelho-2:#B22C3A;
$cor-cinza-claro:#F8F8F8;
$cor-verde:#51A8B1;
$cor-cinza:#6B6B6B;
$cor-branco:#ffffff;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
$font:'Open Sans', sans-serif;

$transicao:linear .1s;

*{margin: 0; padding: 0;}
body, html{
    background-color:white;
}

h1, h2, h3, h4, h5, h6, p, a{
    font-family: $font !important;
}
h1, h2, h3, h4, h5, h6{
    font-weight: 600 !important;
}

p{
    font-size: 18px;
    line-height: 24px;
    strong{
        font-weight: bold;
    }
    
}

a{
    &.btn-contato{
        padding: 8px 24px;
        background-color: $cor-verde;
        text-decoration: none;
        color: $cor-branco;
        display: inline-block;
        border-radius: 50px;

        &:hover{
            opacity: .8;
            transition: $transicao;
        }
        
    }
}

figure.box-depoimento{
    padding: 20px;
    box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.2);
    margin: 16px;
    border-radius: 4px;
    img{
        filter: grayscale(1);
    }
    figcaption{
        p{
            font-size: 16px;
        }

        small{
            line-height: 14px;
            font-size: 14px;
            display: block;
            margin-bottom: 4px;
            strong{
                font-size: 16px;
            }
        }

        a{
            
            color: $cor-verde;
            font-weight: bold;
            text-decoration: underline;
        }
    }

    &:hover{
        img{
            filter: grayscale(0);
            transition: $transicao;
        }
    }
}

header{
    background-color: $cor-vermelho;
    box-shadow: 0px 6px 24px 0px rgba(0,0,0,0.2);
    position: relative;
    z-index: 2;
    nav{
        a.navbar-brand{    
            img{
                width: 75%;
            }
        }
    }

    ul.navbar-nav{   
        li{
            a{
                color:$cor-branco ;
                font-weight: bold;
                &:hover{
                    color: aquamarine;
                }
            }
        }
    } 
}

section{  
    &.padrao{
        padding-top: 80px;
        padding-bottom: 80px;
        hgroup{
            text-align: center;
            margin-bottom: 24px;
        }
    }

    &.banner{   
        background-color: $cor-vermelho-2;
        .carousel-indicators{
            position: inherit !important;
            justify-content: flex-end;
        }
    }

    &.incentive{
        h1{
            font-size: 26px;
            color: $cor-vermelho;
            font-weight: 800 !important;
            text-transform: uppercase;
        }
    }

    &.box-de-acao{
        background-color: $cor-vermelho-2;
        color: $cor-branco;
        text-align: center;
    }

    &.premiacao{
        background: linear-gradient(125deg, rgba(235,235,235,1) 0%, rgba(255,255,255,1) 100%);
        hgroup{
            h2{font-size: 32px; color: $cor-verde; margin-bottom: 8px; font-weight: bold !important;}
            h3{font-size: 24px; color: $cor-cinza;}
        }

        h4{
            color: $cor-vermelho-2;
            font-weight: bold !important;
            font-size: 20px;
            margin-bottom: 24px;
        }

        ul{
            padding: 0;
            li{
                font-family: $font;
                font-size: 18px;
                line-height: 22px;
                list-style-position: inside;
                list-style-image: url(../images/setas-lista.svg);
                padding: 4px 0;
                margin-bottom: 4px;
            }
        }
    }

    &.depoimentos{
        hgroup{
            h5{
                color: $cor-verde; 
                font-size: 36px;
                font-weight: bold !important;
                margin-bottom: 4px;
            }
            h6{color: $cor-cinza; font-size: 20px;}
        }

    }
}
/*Final das configurações das seções*/

.depoimentos-slider{
    width: 100%;
}

.slick-prev:before, .slick-next:before {
   
    color: $cor-vermelho-2 !important;
}

.carousel{
    background-color: $cor-vermelho-2;
}
.carousel .container{
    position: relative;
}

.formulario{
    width: 100%;
    height: auto;   
    padding: 24px;

    legend, p{
        font-size: 16px;
        font-weight: bold;
        color: $cor-branco;
    }

    label{ 
        display: block;
        display: none;
    }

    small{
        font-size: 12px;
        color: $cor-branco;
    }

    input[type='text'], input[type='number'], input[type='phone'], input[type='email'], textarea, select{ 
        background-color: $cor-vermelho-2;
        border: 2px solid $cor-branco;
        color: $cor-branco;
        display: block;
        padding:4px;
        outline: 0;
        width: 100%;
        font-size: 14px;
        &::placeholder{
            color: $cor-branco;
        }

        &:focus{
            background-color: $cor-branco;
            color: $cor-vermelho-2;
            transition: $transicao;
            &::placeholder{
                color: $cor-vermelho-2;
            }
        }
    }

    input[type='submit']{
        border: none;
        background-color: $cor-verde;
        color: $cor-branco;
        padding: 8px 16px;

        &:hover{
            opacity: .8;
            transition: $transicao;
        }
    }
   
}

footer{
    padding-top: 28px;
    padding-bottom: 28px;
    background: $cor-cinza-claro;

    hr.divisor{
        border: 0;
        height: 100px;
        width: 4px;
        border-radius: 50px;
        background: rgba(0,0,0,0.2);
        margin: 0 auto;
    }

    ul.icones-social-media{
        padding: 0;
        li{
            display: inline;
           
        }
    }
}

.base{
    padding-top: 20px;
    padding-bottom: 20px;
}

// Tablets
@media (min-width: 575.99px) and (max-width: 1199.98px) { 
    img.img-divisor-incentivo{
        transform: rotate(90deg);
    } 

    section{
        padding-bottom: 40px;
        
        &.padrao{
            padding-top: 60px;
            padding-bottom: 60px;
            
        }
    }

    footer{
        padding-top: 40px;
        padding-bottom: 40px;
        text-align: center;
        img.logotipo-rodape{
            width: 50% !important;
            margin: 0 auto;
        }

        hr.divisor, hr{
            border: 0;
            height: 2px;
            width: 75%;
            border-radius: 50px;
            background: rgba(0,0,0,0.2);
            margin: 16px auto;
        }
    }
}

// Smartphones
@media (max-width: 575.98px) { 

    section{
        &.padrao{
            padding-top: 60px;
            padding-bottom: 60px;
            text-align: center;
        }

        &.incentive{
            h1{
                font-size: 20px;
                color: $cor-vermelho;
                font-weight: 800 !important;
                text-transform: uppercase;
                margin-bottom: 18px;                
            }
        }

       &.premiacao{
        hgroup{
            h2{font-size: 24px;}
            h3{font-size: 18px;}
        }
        h4{
            font-size: 16px;           
        }
        ul{
            padding: 0;
            li{
                font-size: $font;
                display: block;
                background-color: $cor-vermelho-2;
                color: $cor-branco;
                margin-bottom: 4px;
                font-size: 18px;
                line-height: 22px;
                list-style: none;
                padding: 8px;
            }
        }
       }
    }

    footer{
        text-align: center;
        img.logotipo-rodape{
            width: 50% !important;
            margin: 0 auto;
        }
        
        a{
        color: $cor-cinza;
        }

        hr.divisor, hr{
            border: 0;
            height: 2px;
            width: 75%;
            border-radius: 50px;
            background: rgba(0,0,0,0.2);
            margin: 16px auto;
        }
    }
 }